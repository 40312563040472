.imageContainer{
    width: 100%; 
        padding-bottom: 100%;
        position: relative;

}

.imageLink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}
.imageLink:hover {
    transform  : scale(1.1);
    transition: 0.2s ease-in-out;
}

.bottomSideHeading {
    position: absolute;
    right: 0;
    bottom: 20px;
    max-width: 200px;
    background-color: var(--bottom-side-heading-background);
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
z-index: 0;

}