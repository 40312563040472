.navbar {
    display: flex;
    height:  50px;
    position : sticky;
    top : 0;
    z-index: 6888;
    background-color: white;  
}

.menu-desktop {
    width: 100%;
    display: none;
    position : relative;
}

.menu-mobile {
    justify-content: space-between;
    width : 100%;
    display: flex;
    height : 70px!important;
    position: relative;
}

@media (min-width: 890px) {
    .menu-desktop {
        display: flex;
        
    }

    .menu-mobile {
        display: none!important;
    
    }
}

.ant-menu-item-selected{
    color : white!important;
    background-color: var(--heading-color)!important;
    display :flex;
    justify-content: center;
    align-items: center;
    
    padding :  0 10px !important;
   
   
}
.ant-menu-item-selected::after{
    border : none!important;
  
}
.ant-menu-item, .ant-menu-item-active{
    border  : none!important;
font-size: 17px!important;
font-weight: bold;

/* border-right :1px solid gray!important; */

/* width : 100%; */
/* border : 2px solid green!important; */

margin-top : -2px!important;


padding : 30px 10px !important;
display : flex!important;
justify-content: center!important;

align-items: center!important;
}
@media (max-width: 1300px) {
    .ant-menu-item,.ant-menu-item-active {
        font-size: 14px!important;
        /* Decrease font size */
        /* padding: 10px 0 !important; */
        /* Decrease padding */
    }
}
@media (max-width: 1240px) {
    .ant-menu-item,.ant-menu-item-active {
        font-size: 13px!important;
        /* Decrease font size */
        /* padding: 10px 0 !important; */
        /* Decrease padding */
    }
}

/* When the container width is less than or equal to 400px */
@media (max-width: 400px) {
    .ant-menu-item.ant-menu-item-active {
        font-size: 13px;
        /* Further decrease font size */
        /* padding: 8px 0 !important; */
        /* Further decrease padding */
    }
}

/* When the container width is less than or equal to 300px */
@media (max-width: 300px) {
    .ant-menu-item.ant-menu-item-active {
        font-size: 8px;
        /* Further decrease font size */
        padding: 6px 0 !important;
        /* Further decrease padding */
    }
}










.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after{
    border: none !important;
}
.menuDesktopList{
    width  : 100%;
}
.ant-menu-overflow ,.ant-menu, .ant-menu-root ,.ant-menu-horizontal, .ant-menu-light {
    width  : 100%!important;
    height:60px !important;
  
    /* width: 75vw!important; */
    display :flex;

    align-items: center;
    /* justify-content: space-center; */
    justify-content: space-evenly;
}

.ant-menu-overflow-item{
    height: 100%!important;
    display : flex!important;
    justify-content: center!important;
    align-items: center!important;
  
   gap : 0!important;
}
.ant-menu-overflow-item .ant-menu-overflow-item-rest .ant-menu-submenu .ant-menu-submenu-horizontal .ant-menu-submenu-selected{
    color : var(--heading-color)!important;


}
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after{
    border: none!important;
}
.ant-btn-default, .ant-btn-icon-only ,.ant-dropdown-trigger, .ant-dropdown-open{
    position : relative;
    right : 20px;
    top :3px;


}
.ant-dropdown-menu ,.ant-dropdown-menu-root, .ant-dropdown-menu-vertical, .ant-dropdown-menu-light{
    display :flex;
    justify-content: center;
    flex-direction: column;
    width : 110vw!important;
    position : absolute!important;
    top :-7px;
    margin: auto!important;
    /* margin-left : 30px!important; */
    padding-top: 10px!important;
    left : -88vw!important;

    padding  :10px !important ;
    /* right : -20px!important; */
    
}

@keyframes moveDown {
    from {
        transform: translateY(-50px);
    }

    to {
        transform: translateY(0);
    }
}

.animated-icon {
    animation: moveDown 1s ease-in-out infinite;
}
 .menuDesktopList {
    position :relative!important;
    width : 100%!important;
  

}

