.flag-dropdown{
    background : none!important;

}
.react-tel-input,.form-control{
    border : none!important;
    width : 100%!important;

}
.react-tel-input .flag-dropdown{
    border:  none;
    margin-left: -12px;
    
}
.form-control{
    margin-left: -12px!important;
    
}