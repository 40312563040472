.image-container {
    position: relative;
  
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: bottom 0.5s;
}

.button {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    border: none;
    cursor: pointer;
    transition: top 0.5s;
}

.image-container:hover .overlay {
    bottom: 0;
}

.image-container:hover .button {
    top: 50%;
}