.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: #000;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
}

.prev-arrow {
    left: 10px;
    /* Adjust the position as needed */
    color :red ;
}

.next-arrow {
    right: 10px;
    /* Adjust the position as needed */
}

.carousel-slide {
    position: relative;
}

.carousel-slide .custom-arrow {
    display: none;
}

.carousel-slide:hover .custom-arrow {
    display: block;
}